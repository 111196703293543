exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-allaboutsite-template-js-content-file-path-src-articles-about-mdx": () => import("./../../../src/templates/allaboutsite-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/articles/about.mdx" /* webpackChunkName: "component---src-templates-allaboutsite-template-js-content-file-path-src-articles-about-mdx" */),
  "component---src-templates-allaboutsite-template-js-content-file-path-src-articles-cookies-policy-mdx": () => import("./../../../src/templates/allaboutsite-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/articles/cookies-policy.mdx" /* webpackChunkName: "component---src-templates-allaboutsite-template-js-content-file-path-src-articles-cookies-policy-mdx" */),
  "component---src-templates-allaboutsite-template-js-content-file-path-src-articles-privacy-policy-mdx": () => import("./../../../src/templates/allaboutsite-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/articles/privacy-policy.mdx" /* webpackChunkName: "component---src-templates-allaboutsite-template-js-content-file-path-src-articles-privacy-policy-mdx" */),
  "component---src-templates-allaboutsite-template-js-content-file-path-src-articles-terms-conditions-mdx": () => import("./../../../src/templates/allaboutsite-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/articles/terms-conditions.mdx" /* webpackChunkName: "component---src-templates-allaboutsite-template-js-content-file-path-src-articles-terms-conditions-mdx" */),
  "component---src-templates-article-template-js-content-file-path-src-articles-category-1-accessories-girls-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/articles/category1/accessories-girls.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-src-articles-category-1-accessories-girls-mdx" */),
  "component---src-templates-article-template-js-content-file-path-src-articles-category-1-cindrella-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/articles/category1/cindrella.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-src-articles-category-1-cindrella-mdx" */),
  "component---src-templates-article-template-js-content-file-path-src-articles-category-1-dolls-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/articles/category1/dolls.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-src-articles-category-1-dolls-mdx" */),
  "component---src-templates-article-template-js-content-file-path-src-articles-category-1-dress-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/articles/category1/dress.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-src-articles-category-1-dress-mdx" */),
  "component---src-templates-article-template-js-content-file-path-src-articles-category-1-family-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/articles/category1/family.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-src-articles-category-1-family-mdx" */),
  "component---src-templates-article-template-js-content-file-path-src-articles-category-1-friends-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/articles/category1/friends.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-src-articles-category-1-friends-mdx" */),
  "component---src-templates-article-template-js-content-file-path-src-articles-category-1-hearts-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/articles/category1/hearts.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-src-articles-category-1-hearts-mdx" */),
  "component---src-templates-article-template-js-content-file-path-src-articles-category-1-hijab-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/articles/category1/hijab.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-src-articles-category-1-hijab-mdx" */),
  "component---src-templates-article-template-js-content-file-path-src-articles-category-1-princess-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/articles/category1/princess.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-src-articles-category-1-princess-mdx" */),
  "component---src-templates-article-template-js-content-file-path-src-articles-category-10-mountains-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/articles/category10/mountains.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-src-articles-category-10-mountains-mdx" */),
  "component---src-templates-article-template-js-content-file-path-src-articles-category-10-nature-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/articles/category10/nature.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-src-articles-category-10-nature-mdx" */),
  "component---src-templates-article-template-js-content-file-path-src-articles-category-10-trees-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/articles/category10/trees.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-src-articles-category-10-trees-mdx" */),
  "component---src-templates-article-template-js-content-file-path-src-articles-category-11-mandala-1-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/articles/category11/mandala1.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-src-articles-category-11-mandala-1-mdx" */),
  "component---src-templates-article-template-js-content-file-path-src-articles-category-11-simple-shapes-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/articles/category11/simple_shapes.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-src-articles-category-11-simple-shapes-mdx" */),
  "component---src-templates-article-template-js-content-file-path-src-articles-category-11-zakhrafa-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/articles/category11/zakhrafa.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-src-articles-category-11-zakhrafa-mdx" */),
  "component---src-templates-article-template-js-content-file-path-src-articles-category-12-cars-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/articles/category12/cars.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-src-articles-category-12-cars-mdx" */),
  "component---src-templates-article-template-js-content-file-path-src-articles-category-12-motorcycles-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/articles/category12/motorcycles.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-src-articles-category-12-motorcycles-mdx" */),
  "component---src-templates-article-template-js-content-file-path-src-articles-category-12-truckers-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/articles/category12/truckers.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-src-articles-category-12-truckers-mdx" */),
  "component---src-templates-article-template-js-content-file-path-src-articles-category-13-birds-1-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/articles/category13/birds1.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-src-articles-category-13-birds-1-mdx" */),
  "component---src-templates-article-template-js-content-file-path-src-articles-category-13-parrots-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/articles/category13/parrots.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-src-articles-category-13-parrots-mdx" */),
  "component---src-templates-article-template-js-content-file-path-src-articles-category-13-peacock-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/articles/category13/peacock.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-src-articles-category-13-peacock-mdx" */),
  "component---src-templates-article-template-js-content-file-path-src-articles-category-2-boxing-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/articles/category2/boxing.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-src-articles-category-2-boxing-mdx" */),
  "component---src-templates-article-template-js-content-file-path-src-articles-category-2-boys-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/articles/category2/boys.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-src-articles-category-2-boys-mdx" */),
  "component---src-templates-article-template-js-content-file-path-src-articles-category-2-fighters-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/articles/category2/fighters.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-src-articles-category-2-fighters-mdx" */),
  "component---src-templates-article-template-js-content-file-path-src-articles-category-2-football-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/articles/category2/football.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-src-articles-category-2-football-mdx" */),
  "component---src-templates-article-template-js-content-file-path-src-articles-category-2-mysister-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/articles/category2/mysister.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-src-articles-category-2-mysister-mdx" */),
  "component---src-templates-article-template-js-content-file-path-src-articles-category-2-ninja-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/articles/category2/ninja.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-src-articles-category-2-ninja-mdx" */),
  "component---src-templates-article-template-js-content-file-path-src-articles-category-2-police-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/articles/category2/police.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-src-articles-category-2-police-mdx" */),
  "component---src-templates-article-template-js-content-file-path-src-articles-category-2-robots-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/articles/category2/robots.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-src-articles-category-2-robots-mdx" */),
  "component---src-templates-article-template-js-content-file-path-src-articles-category-2-tools-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/articles/category2/tools.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-src-articles-category-2-tools-mdx" */),
  "component---src-templates-article-template-js-content-file-path-src-articles-category-3-ballons-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/articles/category3/ballons.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-src-articles-category-3-ballons-mdx" */),
  "component---src-templates-article-template-js-content-file-path-src-articles-category-3-body-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/articles/category3/body.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-src-articles-category-3-body-mdx" */),
  "component---src-templates-article-template-js-content-file-path-src-articles-category-3-cake-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/articles/category3/cake.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-src-articles-category-3-cake-mdx" */),
  "component---src-templates-article-template-js-content-file-path-src-articles-category-3-candy-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/articles/category3/candy.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-src-articles-category-3-candy-mdx" */),
  "component---src-templates-article-template-js-content-file-path-src-articles-category-3-clothes-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/articles/category3/clothes.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-src-articles-category-3-clothes-mdx" */),
  "component---src-templates-article-template-js-content-file-path-src-articles-category-3-clown-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/articles/category3/clown.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-src-articles-category-3-clown-mdx" */),
  "component---src-templates-article-template-js-content-file-path-src-articles-category-3-farm-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/articles/category3/farm.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-src-articles-category-3-farm-mdx" */),
  "component---src-templates-article-template-js-content-file-path-src-articles-category-3-school-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/articles/category3/school.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-src-articles-category-3-school-mdx" */),
  "component---src-templates-article-template-js-content-file-path-src-articles-category-3-toy-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/articles/category3/toy.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-src-articles-category-3-toy-mdx" */),
  "component---src-templates-article-template-js-content-file-path-src-articles-category-4-horof-2-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/articles/category4/horof2.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-src-articles-category-4-horof-2-mdx" */),
  "component---src-templates-article-template-js-content-file-path-src-articles-category-4-horof-latin-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/articles/category4/horof_latin.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-src-articles-category-4-horof-latin-mdx" */),
  "component---src-templates-article-template-js-content-file-path-src-articles-category-4-horof-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/articles/category4/horof.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-src-articles-category-4-horof-mdx" */),
  "component---src-templates-article-template-js-content-file-path-src-articles-category-5-numbers-2-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/articles/category5/numbers2.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-src-articles-category-5-numbers-2-mdx" */),
  "component---src-templates-article-template-js-content-file-path-src-articles-category-5-numbers-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/articles/category5/numbers.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-src-articles-category-5-numbers-mdx" */),
  "component---src-templates-article-template-js-content-file-path-src-articles-category-6-flowers-1-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/articles/category6/flowers1.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-src-articles-category-6-flowers-1-mdx" */),
  "component---src-templates-article-template-js-content-file-path-src-articles-category-6-flowers-2-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/articles/category6/flowers2.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-src-articles-category-6-flowers-2-mdx" */),
  "component---src-templates-article-template-js-content-file-path-src-articles-category-6-flowers-mandala-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/articles/category6/flowers_mandala.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-src-articles-category-6-flowers-mandala-mdx" */),
  "component---src-templates-article-template-js-content-file-path-src-articles-category-6-vase-flowers-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/articles/category6/vase_flowers.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-src-articles-category-6-vase-flowers-mdx" */),
  "component---src-templates-article-template-js-content-file-path-src-articles-category-7-hello-kitty-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/articles/category7/hello_kitty.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-src-articles-category-7-hello-kitty-mdx" */),
  "component---src-templates-article-template-js-content-file-path-src-articles-category-7-masha-bear-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/articles/category7/masha_bear.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-src-articles-category-7-masha-bear-mdx" */),
  "component---src-templates-article-template-js-content-file-path-src-articles-category-7-mikey-mouse-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/articles/category7/mikey_mouse.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-src-articles-category-7-mikey-mouse-mdx" */),
  "component---src-templates-article-template-js-content-file-path-src-articles-category-7-sponge-bob-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/articles/category7/sponge_bob.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-src-articles-category-7-sponge-bob-mdx" */),
  "component---src-templates-article-template-js-content-file-path-src-articles-category-8-animals-afrique-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/articles/category8/animals_afrique.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-src-articles-category-8-animals-afrique-mdx" */),
  "component---src-templates-article-template-js-content-file-path-src-articles-category-8-animals-cute-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/articles/category8/animals_cute.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-src-articles-category-8-animals-cute-mdx" */),
  "component---src-templates-article-template-js-content-file-path-src-articles-category-8-bears-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/articles/category8/bears.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-src-articles-category-8-bears-mdx" */),
  "component---src-templates-article-template-js-content-file-path-src-articles-category-8-butterfly-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/articles/category8/butterfly.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-src-articles-category-8-butterfly-mdx" */),
  "component---src-templates-article-template-js-content-file-path-src-articles-category-8-cats-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/articles/category8/cats.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-src-articles-category-8-cats-mdx" */),
  "component---src-templates-article-template-js-content-file-path-src-articles-category-8-dinosaurs-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/articles/category8/dinosaurs.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-src-articles-category-8-dinosaurs-mdx" */),
  "component---src-templates-article-template-js-content-file-path-src-articles-category-8-dogs-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/articles/category8/dogs.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-src-articles-category-8-dogs-mdx" */),
  "component---src-templates-article-template-js-content-file-path-src-articles-category-8-fish-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/articles/category8/fish.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-src-articles-category-8-fish-mdx" */),
  "component---src-templates-article-template-js-content-file-path-src-articles-category-8-horses-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/articles/category8/horses.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-src-articles-category-8-horses-mdx" */),
  "component---src-templates-article-template-js-content-file-path-src-articles-category-8-rabbits-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/articles/category8/rabbits.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-src-articles-category-8-rabbits-mdx" */),
  "component---src-templates-article-template-js-content-file-path-src-articles-category-9-fruits-1-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/articles/category9/fruits1.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-src-articles-category-9-fruits-1-mdx" */),
  "component---src-templates-article-template-js-content-file-path-src-articles-category-9-fruits-basket-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/articles/category9/fruits_basket.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-src-articles-category-9-fruits-basket-mdx" */),
  "component---src-templates-article-template-js-content-file-path-src-articles-category-9-strawberry-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/articles/category9/strawberry.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-src-articles-category-9-strawberry-mdx" */),
  "component---src-templates-blog-details-template-js-content-file-path-src-blog-blog-1-mdx": () => import("./../../../src/templates/blogDetails-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/blog/blog1.mdx" /* webpackChunkName: "component---src-templates-blog-details-template-js-content-file-path-src-blog-blog-1-mdx" */),
  "component---src-templates-blog-details-template-js-content-file-path-src-blog-blog-2-mdx": () => import("./../../../src/templates/blogDetails-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/blog/blog2.mdx" /* webpackChunkName: "component---src-templates-blog-details-template-js-content-file-path-src-blog-blog-2-mdx" */),
  "component---src-templates-blog-details-template-js-content-file-path-src-blog-blog-3-mdx": () => import("./../../../src/templates/blogDetails-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/blog/blog3.mdx" /* webpackChunkName: "component---src-templates-blog-details-template-js-content-file-path-src-blog-blog-3-mdx" */),
  "component---src-templates-blog-details-template-js-content-file-path-src-blog-blog-4-mdx": () => import("./../../../src/templates/blogDetails-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/blog/blog4.mdx" /* webpackChunkName: "component---src-templates-blog-details-template-js-content-file-path-src-blog-blog-4-mdx" */),
  "component---src-templates-category-template-js-content-file-path-src-catigories-coloring-animals-mdx": () => import("./../../../src/templates/category-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/catigories/coloring_animals.mdx" /* webpackChunkName: "component---src-templates-category-template-js-content-file-path-src-catigories-coloring-animals-mdx" */),
  "component---src-templates-category-template-js-content-file-path-src-catigories-coloring-anime-mdx": () => import("./../../../src/templates/category-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/catigories/coloring_anime.mdx" /* webpackChunkName: "component---src-templates-category-template-js-content-file-path-src-catigories-coloring-anime-mdx" */),
  "component---src-templates-category-template-js-content-file-path-src-catigories-coloring-birds-mdx": () => import("./../../../src/templates/category-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/catigories/coloring_birds.mdx" /* webpackChunkName: "component---src-templates-category-template-js-content-file-path-src-catigories-coloring-birds-mdx" */),
  "component---src-templates-category-template-js-content-file-path-src-catigories-coloring-boys-mdx": () => import("./../../../src/templates/category-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/catigories/coloring_boys.mdx" /* webpackChunkName: "component---src-templates-category-template-js-content-file-path-src-catigories-coloring-boys-mdx" */),
  "component---src-templates-category-template-js-content-file-path-src-catigories-coloring-cars-mdx": () => import("./../../../src/templates/category-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/catigories/coloring_cars.mdx" /* webpackChunkName: "component---src-templates-category-template-js-content-file-path-src-catigories-coloring-cars-mdx" */),
  "component---src-templates-category-template-js-content-file-path-src-catigories-coloring-flowers-mdx": () => import("./../../../src/templates/category-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/catigories/coloring_flowers.mdx" /* webpackChunkName: "component---src-templates-category-template-js-content-file-path-src-catigories-coloring-flowers-mdx" */),
  "component---src-templates-category-template-js-content-file-path-src-catigories-coloring-fruits-mdx": () => import("./../../../src/templates/category-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/catigories/coloring_fruits.mdx" /* webpackChunkName: "component---src-templates-category-template-js-content-file-path-src-catigories-coloring-fruits-mdx" */),
  "component---src-templates-category-template-js-content-file-path-src-catigories-coloring-girls-mdx": () => import("./../../../src/templates/category-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/catigories/coloring_girls.mdx" /* webpackChunkName: "component---src-templates-category-template-js-content-file-path-src-catigories-coloring-girls-mdx" */),
  "component---src-templates-category-template-js-content-file-path-src-catigories-coloring-kids-mdx": () => import("./../../../src/templates/category-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/catigories/coloring_kids.mdx" /* webpackChunkName: "component---src-templates-category-template-js-content-file-path-src-catigories-coloring-kids-mdx" */),
  "component---src-templates-category-template-js-content-file-path-src-catigories-coloring-letters-mdx": () => import("./../../../src/templates/category-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/catigories/coloring_letters.mdx" /* webpackChunkName: "component---src-templates-category-template-js-content-file-path-src-catigories-coloring-letters-mdx" */),
  "component---src-templates-category-template-js-content-file-path-src-catigories-coloring-mandala-mdx": () => import("./../../../src/templates/category-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/catigories/coloring_mandala.mdx" /* webpackChunkName: "component---src-templates-category-template-js-content-file-path-src-catigories-coloring-mandala-mdx" */),
  "component---src-templates-category-template-js-content-file-path-src-catigories-coloring-nature-mdx": () => import("./../../../src/templates/category-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/catigories/coloring_nature.mdx" /* webpackChunkName: "component---src-templates-category-template-js-content-file-path-src-catigories-coloring-nature-mdx" */),
  "component---src-templates-category-template-js-content-file-path-src-catigories-coloring-numbers-mdx": () => import("./../../../src/templates/category-template.js?__contentFilePath=/home/medarian/Desktop/taalimatfal/src/catigories/coloring_numbers.mdx" /* webpackChunkName: "component---src-templates-category-template-js-content-file-path-src-catigories-coloring-numbers-mdx" */)
}

